import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×6\\@80% 1RM`}</p>
    <p>{`Shoulder Bridge 4×6 (weighted)`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`5-`}{`*`}{`Back Squat Bear Complex (115/75)`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`4-Back Squat Bear Complex`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`3-Back Squat Bear Complex`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`2-Back Squat Bear Complex`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`1-Back Squat Bear Complex`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`1 rep=squat clean or power clean to thruster, 5 back squats, bar back
to floor.  Can thruster or push press off back on 5th rep.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00 so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      